<template>

  	<div id="container">
    	<div id="contents">
			<div class="contents_body">
				<div class="Board">
				<table class="Board_type2">
					<colgroup>
					<col width="*">
					<col width="150">
					</colgroup>
					<thead>
					<tr>
						<th><span class="noti" v-if="data.noticeAt == 'Y'">공지</span><span>{{data.nttSj}}</span></th>
						<th class="date">{{ data.frstRegistPnttm | moment('YYYY-MM-DD') }}</th>
					</tr>
					</thead>
					<tbody>									
					<tr>
						<td colspan="2" class="content">
						<span>{{data.nttCn2}}</span>
						</td>
					</tr>
					<tr v-for="attaFile in data.attaList" :key="attaFile.attaFileUniqHash">
						<td colspan="2">
						<span class="file"></span>
						<span class="filebtn" @click="attaDownload(attaFile.attaFileUniqHash)">{{attaFile.orgFileNm }}</span>
						</td>
					</tr>              
					</tbody>
				</table>
				</div>
				
				<!--버튼영역-->
				<div class="BtnArea">
					<div class="Btninner">
						<span class="btn list" @click="btnList()">목록</span>
					</div>
				</div>
				<!--//버튼영역-->

				<!--댓글-->
				<div class="ReplyArea" ref="ref_reply">
					<div class="Replyinner">
						<div class="ReplyTit">
							댓글 <span class="cnt">{{ data.commentCnt }}</span>개 <span class="meg">(회원 로그인을 하시면 댓글 작성을 하실 수 있습니다.)</span>
						</div>
						<div>
							<textarea class="Replytxt" v-model="comment"></textarea><div class="btn reply" @click="regComment" id="commentBtn">{{commentBtnName}}</div>
						</div>
						<div class="Replyitem first" v-for="item in data.commentList" :key="item.commentNo">
							<div class="item">
								<div class="loginfo">{{ item.wrterId2 }} &nbsp;&nbsp; {{ item.frstRegistPnttm | moment('YYYY-MM-DD') }}</div>
								<div class="replymeg">{{ item.comment }}</div>
							</div>
							<div class="btnitem">
								<!-- TODO : 본인 댓글일때만 수정, 삭제하도록 -->
								<div><img src="/images/board/btn_edit_s.png" alt="수정" @click="modComment(item.commentNo, item.comment)"></div>
								<div><img src="/images/board/btn_delete_s.png" alt="삭제" @click="delComment(item.commentNo)"></div>
							</div>
						</div>						
					</div>
				</div>
				<!--//댓글-->

			</div><!--//contents_body-->
		</div><!--//contents-->
	</div><!--//container-->  

</template>

<script>

  export default {

    data() {
		return {

			data : {},
			
			commentNo : '',
			comment : '',
		
			commentBtnName : '댓글쓰기',
		}
    },

    beforeCreate() {
      // console.log('beforeCreate');
    },
    created() {
      // console.log('created');
    },
    beforeMount() {
      // console.log('beforeMount');
    },
    mounted() {
      // console.log('mounted');
      this.getDetail();
    },
    beforeUpdate() {
      // console.log('beforeUpdate');
    },
    updated() {
      // console.log('update');
    },
    beforeDestroy() {
      // console.log('beforeDestroy');
    },
    destroyed() {
      // console.log('destroyed');
    },

    methods : {

		getDetail() {

			var param = {
				bbsId : this.$route.query.bbsId,
				nttId : this.$route.query.nttId,
			};

			// console.log("param", param);
			this.$.httpPost('/api/bbs/getBbs', param)
			.then(res => {
				// console.log(res.data);
				
				this.data = res.data;

				this.commentBtnName = '댓글쓰기';
				this.commentNo = '';
				this.comment = '';
			})
			.catch(err => {
				// console.log('============================='); 
				// console.log(err);
				// console.log(err.response);
				// console.log('============================='); 
			
				alert(err.response.data.error_description);
			});
		},

		btnList() {
			this.$router.push("/bbs/BBS001M01");
		},

		regComment() {
			
			var param = {
				bbsId: this.$route.query.bbsId,
				nttId: this.$route.query.nttId,
				
				commentNo : this.commentNo,
				comment : this.comment
			};

			var url = '';

			if(this.commentNo == '') {
				// 댓글등록
				url = '/api/bbs/regComment';
			} else {
				// 댓글수정
				url = '/api/bbs/modComment';
			}
			// console.log("param", param);
			this.$.httpPost(url, param)
				.then(() => {
					// console.log(res.data);
					this.getDetail();
				})
				.catch(err => {
					// console.log('=============================');
					// console.log(err);
					// console.log(err.response);
					// console.log('=============================');
					alert(err.response.data.error_description);
				});
		},

		modComment(commentNo, comment) {
			this.commentBtnName = '댓글수정';
			this.commentNo = commentNo;
			this.comment = comment;

			this.$refs.ref_reply.scrollIntoView({behavior : 'smooth'});
		},

		delComment(commentNo) {

			if(!confirm('삭제하시겠습니까?')) {
				return;
			}
			var param = {
				bbsId: this.$route.query.bbsId,
				nttId: this.$route.query.nttId,
				
				commentNo : commentNo,
			};

			this.$.httpPost('/api/bbs/delComment', param)
				.then(() => {
					// console.log(res.data);
					this.getDetail();
				})
				.catch(err => {
					// console.log('=============================');
					// console.log(err);
					// console.log(err.response);
					// console.log('=============================');
					alert(err.response.data.error_description);
				});

		},

		attaDownload(hash) {

			var params = {
				fileKey : hash
			}

			this.$.fileDownload('/api/bbs/fileDownload', params)
				.catch(err => {
					// console.log('=============================');
					// console.log(err);
					// console.log(err.response);
					// console.log('=============================');
					alert(err.response.data.error_description);
				})
		}		
      
    }
  }
</script>